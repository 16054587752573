import type { MutableRefObject } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import clsx from 'clsx';
import type { AnalyticsFunction } from '../../types';
import { AccordionThemes } from '../../types';
import { Slideshow } from '../Slideshow/Slideshow';
import Badge from '../Badge';
import { ThumbnailSelect } from '../ThumbnailSelect/ThumbnailSelect';
import { ProductHeader } from '../ProductHeader/ProductHeader';
import type { AccordionSection } from '../Accordion/Accordion';
import { Accordion } from '../Accordion/Accordion';
import styles from './productsDetailsGrid.module.scss';

export interface ProductDetailsGridProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle?: string;
  price: string;
  originalPrice?: string;
  isProDiscount?: boolean;
  withWhoopProPrice?: string;
  isFancy?: boolean;
  media?: React.ReactNode[];
  accordions?: AccordionSection[];
  previewClassName?: string;
  infoClassName?: string;
  scrollParentRef?: MutableRefObject<HTMLDivElement | null>;
  onImageChange?: (index: number) => void;
  noImageScroll?: boolean;
  imageMessage?: string;
  description?: string;
  footer?: React.ReactNode;
  reviewStars?: React.ReactNode;
  onAnalyticsEvent?: AnalyticsFunction;
}

const SCROLL_THRESHOLD = -1;

export function ProductDetailsGrid({
  children,
  media,
  title,
  price,
  originalPrice,
  withWhoopProPrice,
  isProDiscount,
  isFancy,
  accordions,
  previewClassName,
  infoClassName,
  className,
  scrollParentRef,
  onImageChange,
  noImageScroll,
  subTitle,
  imageMessage,
  description,
  reviewStars,
  footer,
  onAnalyticsEvent,
  ...props
}: ProductDetailsGridProps): JSX.Element {
  const { t } = useTranslation('accessoriesPage');
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [scroll, setScroll] = useState(false);
  const shouldScroll = !noImageScroll && scroll;

  const changeImageIndex = (index: number): void => {
    onImageChange && onImageChange(selectedImageIndex);
    setSelectedImageIndex(index);
  };

  useEffect(() => {
    // we need a local variable to save the curren value from the ref to pass it to the cleanup function
    const localRef = scrollParentRef?.current;

    if (localRef) {
      const element: HTMLElement = localRef;
      element.addEventListener('scroll', () => {
        setScroll(element.scrollTop > SCROLL_THRESHOLD);
      });
      setScroll(element.scrollTop > SCROLL_THRESHOLD);
    } else {
      const element: Window & typeof globalThis = window;
      element.addEventListener('scroll', () => {
        setScroll(element.scrollY > SCROLL_THRESHOLD);
      });
      setScroll(element.scrollY > SCROLL_THRESHOLD);
    }
    return () => {
      //on unmount
      if (localRef) {
        const element: HTMLElement = localRef;
        element.removeEventListener('scroll', () => {
          setScroll(element.scrollTop > SCROLL_THRESHOLD);
        });
        setScroll(element.scrollTop > SCROLL_THRESHOLD);
      } else {
        const element: Window & typeof globalThis = window;
        element.removeEventListener('scroll', () => {
          setScroll(element.scrollY > SCROLL_THRESHOLD);
        });
        setScroll(element.scrollY > SCROLL_THRESHOLD);
      }
    };
  }, [scrollParentRef]);

  return (
    <div
      className={clsx(
        'block max-w-[1000px] justify-center p-0 text-[40px] font-semibold [&_.flickity-viewport_img]:min-h-full',
        'md:flex md:flex-row',
        className,
      )}
      {...props}
    >
      <div
        className={clsx(
          previewClassName,
          styles.sticky,
          'sticky top-0 z-[1] h-full flex-1',
          noImageScroll ? 'static md:sticky' : '',
          shouldScroll
            ? 'shadow-[0_0_24px_0_rgba(0,0,0,0.2)] md:shadow-none'
            : '',
        )}
      >
        <div
          className={clsx(
            'bg-white pb-1 pt-1',
            'md:bg-transparent md:pb-0 md:pt-0',
          )}
        >
          <Slideshow
            className={clsx(
              'm-auto max-h-[100vw] max-w-[100vw] transition-[height] delay-100 duration-[400ms] [&_.flickity-viewport]:min-h-full',
              'md:w-[500px] md:[&_.flickity-viewport]:min-h-0',
              shouldScroll ? 'h-[30vh] w-full md:h-[500px]' : 'h-[500px]',
            )}
            multiImage
            onChange={changeImageIndex}
            overlay={
              imageMessage ? (
                <Badge
                  className='absolute bottom-0 right-0 h-auto'
                  label={imageMessage}
                />
              ) : undefined
            }
            parentSizing
            prevNextButtons
            value={selectedImageIndex}
          >
            {media}
          </Slideshow>
          <ThumbnailSelect
            onChange={changeImageIndex}
            showArrowButtons
            value={selectedImageIndex}
          >
            {media}
          </ThumbnailSelect>
        </div>
        {accordions ? (
          <Accordion
            className={clsx('hidden', 'md:block')}
            sections={accordions}
          />
        ) : undefined}
      </div>

      <div
        className={clsx(
          'max-w-[100vw] flex-[0.8] px-4 py-6',
          'md:max-w-[500px] md:px-9 md:py-0',
          infoClassName,
        )}
      >
        <ProductHeader
          fancy={isFancy}
          isProDiscount={isProDiscount}
          originalPrice={originalPrice}
          price={price}
          reviewStars={reviewStars}
          subTitle={subTitle}
          title={title}
          withWhoopProPrice={withWhoopProPrice}
        />
        {/* eslint-disable-next-line */}
        <>{children}</>
        {description ? (
          <div className={clsx('max-w-none', 'md:max-w-[95%] md:pt-6')}>
            <h1 className='text-2xl font-semibold normal-case tracking-normal'>
              {t('productDetails')}
            </h1>
            <div
              className='py-3 text-sm font-normal'
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        ) : undefined}
        {footer}
      </div>
      {accordions ? (
        <Accordion
          className={clsx('block', 'md:hidden')}
          onSectionClose={({ id, title }) => {
            onAnalyticsEvent?.('Close Product Accordion', { id, title });
          }}
          onSectionOpen={({ id, title }) => {
            onAnalyticsEvent?.('Open Product Accordion', { id, title });
          }}
          sections={accordions}
          theme={AccordionThemes.JOIN_FLOW}
        />
      ) : undefined}
    </div>
  );
}
